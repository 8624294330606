.list-table {
    border: 1px solid #ccc;
  
    thead {
      th {
          width: 25%;
      }
    }
  
    tbody {
      tr {
        &:not(.sb-table-not-found-message) {
          cursor: pointer;
        }
      }
    }
  }
  