.license-page {
  .licenses {
    .notice {
      min-height: auto;
      height: auto;
      padding: 0;
      margin-bottom: 10px;
    }
  }

  .licenses-wrapper {
    .license {
      div {
        margin-top: 5px;
      }

      ul {
        margin-bottom: 5px;
      }

      h3 {
        padding-top: 5px;
      }
    }
  }
}