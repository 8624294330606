.status-bullet {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 5px;

  &.enabled {
    background-color: #00CC66;
  }

  &.disabled {
    background-color: #ff4C4F;
  }

  &.unknown {
    background-color: #888887;
  }
}