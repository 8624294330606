.margin-left-auto {
  margin-left: auto;
}

$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (
  top,
  bottom,
  left,
  right
); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.sb-btn.red,
.sb-btn.red:hover,
.sb-btn.red:focus {
  color: #fc3c50;
  border-color: #fc3c50;
}
.sb-btn.red:active,
.sb-btn.red:enabled:active {
  background-color: #fc3c50;
  color: #fff;
  border-color: #fc3c50;
  background-image: linear-gradient(#fc3d50, #fc3c50);
}
